import React from "react"
import Layout from "../components/layout"
import BackLink from "../components/backlink"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import mapboxgl from "mapbox-gl"

class SubPage extends React.Component {
  createMap(center, zoom, type, route) {
    const { data } = this.props

    var lonlat = center.split(",")

    var lon = lonlat[0]
    var lat = lonlat[1]

    const coordinates = [lon, lat]

    if (route === "true") {
      const map = new mapboxgl.Map({
        center: [-1.832961, 52.564807],
        container: "map",
        style: "mapbox://styles/mapbox/" + type + "-v9",
        zoom: 12,
      })

      map.on("load", function () {
        map.addLayer({
          id: "route",
          type: "line",
          source: {
            type: "geojson",
            data: {
              type: "Feature",
              properties: {},
              geometry: {
                type: "LineString",
                coordinates: [
                  //start
                  [-1.823408, 52.562818],
                  [-1.823055, 52.563242],
                  [-1.823082, 52.563503],
                  [-1.823066, 52.563995],
                  [-1.822403, 52.565015],
                  [-1.822922, 52.565691],
                  [-1.82325, 52.566241],
                  //turn on king eward sq
                  [-1.823994, 52.566099],
                  [-1.824091, 52.566108],
                  [-1.824418, 52.566151],
                  [-1.824648, 52.566285],
                  [-1.82511, 52.566542],
                  [-1.826466, 52.566627],
                  [-1.827485, 52.566105],
                  [-1.833082, 52.559261],
                  [-1.833254, 52.556939],
                  [-1.837565, 52.555543],
                  [-1.843244, 52.554323],
                  //entering the park
                  [-1.843426, 52.554362],
                  [-1.844311, 52.555904],
                  [-1.845062, 52.557332],
                  [-1.845382, 52.557972],
                  [-1.845672, 52.558703],
                  [-1.845806, 52.559306],
                  [-1.845876, 52.55956],
                  [-1.846181, 52.560066],
                  [-1.846358, 52.560255],
                  [-1.846626, 52.560457],
                  [-1.847409, 52.560969],
                  [-1.847548, 52.561064],
                  [-1.847774, 52.561295],
                  [-1.848144, 52.561817],
                  [-1.849034, 52.562645],
                  [-1.849137, 52.562782],
                  [-1.849239, 52.563327],
                  [-1.849271, 52.563783],
                  [-1.849362, 52.564012],
                  [-1.849609, 52.564224],
                  [-1.849866, 52.564361],
                  [-1.850177, 52.564439],
                  [-1.850488, 52.564576],
                  [-1.850601, 52.564648],
                  [-1.851067, 52.564964],
                  [-1.851341, 52.565117],
                  [-1.852172, 52.565515],
                  [-1.852526, 52.565629],
                  [-1.852778, 52.565831],
                  [-1.853464, 52.56618],
                  [-1.854896, 52.56709],
                  [-1.855241, 52.567429],
                  [-1.855777, 52.568153],
                  [-1.856287, 52.568743],
                  [-1.856469, 52.569128],
                  [-1.85678, 52.57005],
                  [-1.856882, 52.570784],
                  [-1.857355, 52.571456],
                  [-1.856519, 52.571117],
                  [-1.855141, 52.57069],
                  [-1.854519, 52.5705],
                  [-1.854159, 52.570347],
                  [-1.85261, 52.569275],
                  [-1.852074, 52.568968],
                  [-1.850438, 52.568225],
                  [-1.849671, 52.567934],
                  [-1.848454, 52.567651],
                  [-1.847677, 52.567439],
                  [-1.84514, 52.566646],
                  [-1.843457, 52.566301],
                  [-1.843092, 52.566265],
                  [-1.842909, 52.566271],
                  [-1.841644, 52.566333],
                  [-1.841086, 52.566281],
                  [-1.840694, 52.566206],
                  [-1.840452, 52.566135],
                  [-1.84027, 52.566046],
                  [-1.840152, 52.565942],
                  [-1.840189, 52.565606],
                  [-1.840173, 52.565293],
                  [-1.84013, 52.565179],
                  [-1.839814, 52.565091],
                  [-1.838859, 52.564905],
                  [-1.83813, 52.564869],
                  [-1.837272, 52.564703],
                  [-1.836361, 52.564608],
                  [-1.836077, 52.564563],
                  [-1.835728, 52.564442],
                  [-1.835433, 52.564292],
                  [-1.835288, 52.564201],
                  [-1.834425, 52.564341],
                  [-1.832966, 52.564807],
                  [-1.832894, 52.564964],
                  [-1.832854, 52.56514],
                  [-1.832894, 52.565319],
                  [-1.832998, 52.565486],
                  //tidy below
                  [-1.834095, 52.566066],
                  [-1.835923, 52.567028],
                  [-1.837109, 52.567252],
                  [-1.839372, 52.567598],
                  [-1.84, 52.56814],
                  [-1.841752, 52.570911],
                  [-1.841494, 52.571569],
                  [-1.840208, 52.572508],
                  [-1.83945, 52.57318],
                  [-1.84493, 52.57777],
                  [-1.845166, 52.579413],
                  [-1.844373, 52.580762],
                  [-1.842678, 52.582253],
                  [-1.841633, 52.583253],
                  [-1.839879, 52.584893],
                  [-1.839311, 52.585137],
                  [-1.839251, 52.585258],
                  [-1.837534, 52.584249],
                  [-1.835003, 52.583466],
                  [-1.834488, 52.583179],
                  [-1.8317, 52.581119],
                  [-1.830055, 52.579621],
                  [-1.829368, 52.578624],
                  [-1.828999, 52.577325],
                  [-1.828419, 52.576569],
                  [-1.825781, 52.574208],
                  [-1.823958, 52.572344],
                  [-1.822972, 52.570661],
                  [-1.822628, 52.569827],
                  [-1.822543, 52.568848],
                  [-1.823272, 52.567088],
                  [-1.823272, 52.566383],
                  [-1.823227, 52.566255],
                ],
              },
            },
          },
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#007acc",
            "line-width": 6,
          },
        })

        map.addLayer({
          id: "route2",
          type: "line",
          source: {
            type: "geojson",
            data: {
              type: "Feature",
              properties: {},
              geometry: {
                type: "LineString",
                coordinates: [
                  //Finishing
                  [-1.823227, 52.566255],
                  [-1.822907, 52.565731],
                  [-1.822414, 52.565118],
                  [-1.822573, 52.564774],
                  [-1.822938, 52.564259],
                  [-1.823045, 52.563946],
                  [-1.823056, 52.563307],
                  [-1.823077, 52.563137],
                  [-1.823408, 52.562818],
                ],
              },
            },
          },
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#191",
            "line-width": 6,
            "line-dasharray": [0.1, 1.8],
          },
        })
      })
      map.addControl(new mapboxgl.NavigationControl())
    } else {
      const mapPoints = data.contentfulPosts.mapPoints

      var places = mapPoints.map(node => ({
        type: "Feature",
        title: node.title,
        properties: {
          title: node.title,
          description: node.title,
          slug: node.slug,
        },
        geometry: {
          type: "Point",
          coordinates: [node.geocode.lon, node.geocode.lat],
        },
      }))

      const map = new mapboxgl.Map({
        center: coordinates,
        container: "map",
        style: "mapbox://styles/mapbox/" + type + "-v9",
        zoom: zoom,
      })

      map.on("load", function () {
        map.addLayer({
          id: "Places",
          type: "circle",
          source: {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: places,
            },
          },

          paint: {
            "circle-radius": 7,
            "circle-color": "#007cbf",
            "circle-opacity": 0.8,
            "circle-stroke-width": 0,
          },
        })
      })
      map.addControl(new mapboxgl.NavigationControl())

      map.on("click", "Places", function (e) {
        var coordinates = e.features[0].geometry.coordinates.slice()
        var description =
          "<h3>" + e.features[0].properties.description + "</h3>"
        var locationLink =
          "<a href=/location/" +
          e.features[0].properties.slug +
          ">click for info</a>"

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
        }

        new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(description + locationLink)
          .addTo(map)
        var clickedPoint = e.features[0]
        flyToStore(clickedPoint)
      })

      function flyToStore(currentFeature) {
        map.flyTo({
          center: currentFeature.geometry.coordinates,
        })
      }

      map.on("mouseenter", "Places", function () {
        map.getCanvas().style.cursor = "pointer"
      })

      map.on("mouseleave", "Places", function () {
        map.getCanvas().style.cursor = ""
      })
    }
  }

  componentDidMount() {
    const page = this.props.data.contentfulPosts

    if (page.body.childMarkdownRemark.html.indexOf("[map") >= 1) {
      var startpoint = page.body.childMarkdownRemark.html.indexOf("[map") - 28
      var endpoint = page.body.childMarkdownRemark.html.indexOf("]</code>") + 8

      var complete = page.body.childMarkdownRemark.html.substring(
        startpoint,
        endpoint
      )
      //strip shortcode leaving center and zoom
      complete = complete.replace('<code class="language-text">[map ', "")
      complete = complete.replace("]</code>", "")
      complete = complete.split(" ")
      var center = complete[0].replace("center=&quot;", "")
      var zoom = complete[1].replace("zoom=&quot;", "")
      var type = complete[2].replace("type=&quot;", "")
      if (complete.length > 3) {
        var route = complete[3].replace("route=&quot;", "")
      }
      center = center.replace("&quot;", "")
      zoom = zoom.replace("&quot;", "")
      type = type.replace("&quot;", "")
      if (route) {
        route = route.replace("&quot;", "")
      } else {
        route = null
      }
      mapboxgl.accessToken =
        "pk.eyJ1Ijoic2VyaXRoIiwiYSI6ImNqcGI1eXN4dDBtcWwzcXAyNGsycG0xaWgifQ.Qg3PAYHQ72Qem7VbWCb0ZQ"
      this.createMap(center, zoom, type, route)
    }
  }

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const siteTagline = data.site.siteMetadata.description
    const page = this.props.data.contentfulPosts

    //check for map shortcode
    if (page.body.childMarkdownRemark.html.indexOf("[map") >= 1) {
      var startpoint = page.body.childMarkdownRemark.html.indexOf("[map") - 28
      var endpoint = page.body.childMarkdownRemark.html.indexOf("]</code>") + 8
      var complete = page.body.childMarkdownRemark.html.substring(
        startpoint,
        endpoint
      )
      var bodycontent = page.body.childMarkdownRemark.html.replace(
        complete,
        "<div id='map' class='map'></div>"
      )
    } else {
      bodycontent = page.body.childMarkdownRemark.html
    }

    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        tagline={siteTagline}
      >
        <SEO
          title={page.title}
          keywords={["blog", "gatsby", "javascript", "react"]}
        />
        <div className="post">
          <h1>{page.title}</h1>
          <div
            dangerouslySetInnerHTML={{
              __html: bodycontent,
            }}
          />
          <BackLink origin="blog" />
        </div>
      </Layout>
    )
  }
}

export default SubPage

export const blogQuery = graphql`
  query blogQuery($slug: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    contentfulPosts(slug: { eq: $slug }) {
      id
      title
      body {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 320)
        }
      }
      mapPoints {
        id
        title
        slug
        geocode {
          lon
          lat
        }
      }
    }
  }
`
